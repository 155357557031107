import { TextModalBodyProps } from './TextModalBody.props';
import styles from './TextModalBody.module.scss';
import { CloseButton } from 'components/elements';
import { useEffect, useRef, useState } from 'react';

const policyText = document.getElementById('policy-text');
const rulesText = document.getElementById('rules-text');
const cookieText = document.getElementById('cookie-text');
const termsText = document.getElementById('terms-text');

export const TextModalBody = ({
  type,
  onClose,
}: TextModalBodyProps): JSX.Element => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [title, setTitle] = useState('');

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    if (type === 'policy') {
      contentRef.current.innerHTML = policyText?.innerHTML ?? '';
      setTitle('Политика конфиденциальности');
    } else if (type === 'rules') {
      contentRef.current.innerHTML = rulesText?.innerHTML ?? '';
      setTitle('Пользовательское соглашение');
    } else if (type === 'cookie') {
      contentRef.current.innerHTML = cookieText?.innerHTML ?? '';
      setTitle('Политика использования cookie');
    } else if (type === 'terms') {
      contentRef.current.innerHTML = termsText?.innerHTML ?? '';
      setTitle('Пользовательское соглашение');
    }
  }, [type]);

  return (
    <div className={styles.module}>
      <img className={styles.bg1} src="/build/images/bg-1.svg" alt="bg" />
      <img className={styles.bg2} src="/build/images/bg-2.svg" alt="bg" />
      <CloseButton className={styles.close} onClick={onClose} />
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.content} ref={contentRef} />
    </div>
  );
};
