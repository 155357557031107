import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { openTextModal } from 'store/app/app.slice';
import { openCallback } from 'store/callback/callback.slice';
import { Callback } from 'views/Callback';
import { Booking } from 'views/Booking';
import { TextModal } from 'views/TextModal';
import { Thanks } from 'views/Thanks';
import { openBooking } from 'store/booking/booking.slice';
import { Cookie } from 'views/Cookie';
import { openReview } from 'store/review/review.slice';
import { Review } from 'views/Review';
import { openFromReact } from 'utils/helpers/browser.helpers';
import { openAddReview } from 'store/addReview/addReview.slice';
import { AddReview } from 'views/AddReview';

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    openFromReact('.header__callback', () => dispatch(openCallback()));
    openFromReact('.footer__callback', () => dispatch(openCallback()));

    // openFromReact('.screen__booking', () =>
    //   dispatch(openBooking({ type: 'booking', room: null }))
    // );
    // openFromReact('.screen__calculation', () =>
    //   dispatch(openBooking({ type: 'calculation', room: null }))
    // );

    openFromReact('.rooms__booking', (event) => {
      if (event.target instanceof HTMLElement) {
        dispatch(
          openBooking({
            type: 'booking',
            room: event.target.dataset.room ?? null,
          }),
        );
      }
    });
    openFromReact('.rooms__calculation', (event) => {
      if (event.target instanceof HTMLElement) {
        dispatch(
          openBooking({
            type: 'calculation',
            room: event.target.dataset.room ?? null,
          }),
        );
      }
    });

    openFromReact('.reviews__button', (event) => {
      if (event.target instanceof HTMLElement) {
        dispatch(
          openReview({
            text: event.target.dataset.text ?? '',
            name: event.target.dataset.name ?? '',
            grade: event.target.dataset.grade ?? '',
            date: event.target.dataset.date ?? '',
          }),
        );
      }
    });

    openFromReact('.reviews__add', () => dispatch(openAddReview()));

    openFromReact('#footer-policy', () => dispatch(openTextModal('policy')));
    openFromReact('#footer-rules', () => dispatch(openTextModal('rules')));
    openFromReact('#footer-cookie', () => dispatch(openTextModal('cookie')));
    openFromReact('#footer-terms', () => dispatch(openTextModal('terms')));
  }, [dispatch]);

  return (
    <>
      <Callback />
      <Booking />
      <TextModal />
      <Thanks />
      <Review />
      <AddReview />
      <Cookie />
    </>
  );
};
